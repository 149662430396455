<template>
  <div>
    <div class="empty-state" v-if="loaded && looks.length === 0">
      <div class="title">Outfits wasn't generated yet.</div>
      <div class="description">You can generate outfits with items from catalog.</div>
      <div class="button" @click="goToCatalog">Go to catalog</div>
    </div>

    <div class="outfits">
      <div class="swiper-button-prev" slot="button-prev"></div>
      <swiper ref="mySwiper"
              class="swiper"
              :options="swiperOptions">
        <swiper-slide class="swiper-slide"
                      v-for="look in looks"
                      :key="look.id">
          <look-card class="look" :look="look" />
        </swiper-slide>
      </swiper>
      <div class="swiper-button-next" slot="button-next"></div>
    </div>
  </div>
</template>

<script>
import LookCard from '@/components/Shared/LookCard/LookCard.vue'
import lookService from '@/services/queries/lookQueries'

import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css';

export default {
  components: {
    LookCard,
    Swiper,
    SwiperSlide
  },

  directives: {
    swiper: directive
  },

  metaInfo () {
    return {
      title: 'My Outfits'
    }
  },

  data () {
    return {
      looks: [],
      loaded: false,
      swiperOptions: {
        slidesPerView: 'auto',
        mousewheel: true,
        slidesPerGroup: 2,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },

  created() {
    this.loadLooks()
  },

  methods: {
    async loadLooks () {
      const response = await lookService.get('me', {
        sorting: 'time_created' + '{desc}',
        generate_context: 'state==SUCCESS'
      })

      this.looks = response.items
      this.loaded = true
    },

    goToCatalog () {
      this.$router.push({
        name: 'catalogue'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.swiper {
  width: 1100px;
  margin: 40px auto;
  padding: 20px;
}

.swiper-slide {
  width: 300px;
}

.swiper-button-prev,
.swiper-button-next {
  position: static;
  flex-grow: 1;
  margin-top: 0;
  color: #000;
}

.outfits {
  display: flex;
  align-items: center;
}

.empty-state {
  width: 300px;
  margin: 200px auto;
  text-align: center;

  .title {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 18px;
  }

  .description {
    margin-bottom: 18px;
    font-size: 16px;
    line-height: 24px;
    color: #727272;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    margin: 0 auto;
    padding: 5px 10px;
    background-color: #000;
    color: #fff;
    font-size: 12px;
    border-radius: 0;
  }
}
</style>
